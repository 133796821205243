import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['link', 'viewMore']

    clickHandler(event) {
        const deductibleValue = event.currentTarget.value
        this.sendGetRequest(deductibleValue)
    }

    // Hack I guess
    clickViewLessCallback(event) {
        localStorage.setItem('viewMoreClicked', 'false')
    }

    // Hack I guess
    clickViewMoreCallback(event) {
        localStorage.setItem('viewMoreClicked', 'true')
    }

    updateTargetsHref(targets, deductibleValue) {
        targets.forEach((target) => {
            if (!this.isHidden(target)) {
                target.href = this.updateHrefDeductible(target.href, deductibleValue)
            }
        })
    }

    updateHrefDeductible(href, deductibleValue) {
        const baseUrl = href.split("?")[0]
        const json = this.getParamsFromURL(href);
        const updatedHref = this.updateJsonAndConvertToURL(json, deductibleValue);

        return baseUrl + '?' + updatedHref;
    }

    getParamsFromURL(url) {
        const searchParams = new URLSearchParams(new URL(url).search);
        let jsonParams = {};
        for (const [key, value] of searchParams) {
            if (!key.includes("[]")) {
                jsonParams[key] = value;
            } else {
                const strippedKey = key.replace("[]", "");
                if (!jsonParams[strippedKey]) {
                    jsonParams[strippedKey] = [];
                }
                jsonParams[strippedKey].push(value);
            }
        }
        return jsonParams;
    }

    updateJsonAndConvertToURL(json, newDeductible) {
        json['warranty_quote[deductible]'] = newDeductible;
        let searchParams = new URLSearchParams();
        for (const key in json) {
            if (Array.isArray(json[key])) {
                for (const value of json[key]) {
                    searchParams.append(`${key}[]`, value);
                }
            } else if (typeof json[key] === "object") {
                for (const nestedKey in json[key]) {
                    searchParams.append(`${key}[${nestedKey}]`, json[key][nestedKey]);
                }
            } else {
                searchParams.append(key, json[key]);
            }
        }
        return searchParams.toString();
    }

    updateParagraphElementsWithDeductibleValue(deductibleValue) {
        document.querySelectorAll("p").forEach(p => {
            if (p.textContent.includes("Deductible: $") && !this.isHidden(p)) {
                p.textContent = "Deductible: $" + deductibleValue;
            }
        });
    }

    updatePriceElementsWithDeductibleValue(deductibleValue) {
        document.querySelectorAll(".price").forEach(priceElement => {
            if (!this.isHidden(priceElement)) {
                const originalPriceElement = priceElement.children[0];
                const originalPrice = parseInt(originalPriceElement.value.trim().replace(/[$,]/g, ''));
                let newPrice;
                const xcareRadioBtn = document.querySelector('input[name="deductibleXcare"]');

                if (!this.isHidden(xcareRadioBtn)) {
                    switch (deductibleValue) {
                        case "0":
                          newPrice = originalPrice + 100;
                          break;
                        case "100":
                          newPrice = originalPrice;
                          break;
                        case "500":
                          newPrice = originalPrice - 400;
                          break;
                        default:
                          newPrice = originalPrice;
                      }
                  }

                const formattedPrice = '$' + newPrice.toLocaleString();
                priceElement.childNodes[0].nodeValue = formattedPrice;
            }
        });
    }

    isHidden(el) {
        return (el.offsetParent === null)
    }

    sendGetRequest(deductible) {
     // Get the current URL
     var currentUrl = window.location.href;

     var viewMoreClicked = localStorage.getItem('viewMoreClicked'); // Hack I guess

     // Remove any existing parameters from the URL
     currentUrl = currentUrl.split('?')[0];
     // Append the deductible parameter with its value
     var urlWithParams = currentUrl + '?deductible=' + deductible + '&show_more_rates=' + viewMoreClicked;

     // Navigate to the new URL, triggering a GET request
     window.location.href = urlWithParams;
   }

}
