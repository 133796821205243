import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'addGuarantor',
    'guarantorTemplate',
    'template',
    'button',
    'buttonTemplate',
    'addButton',
  ]

  connect() {
    // Initial active guarantors
    const guarantorForm = Array.from(
      document.getElementsByClassName('nested-guarantor-fields active')
    )
    this.count = guarantorForm.length

    if (this.count > 0) {
      document.getElementById(
        'lease_application_guarantor_check_true'
      ).checked = true
      // want to show the add button if less than 4 already showing
      if (this.count < 4) {
        // Add the add button to the initial guarantor
        const addButton = this.buttonTemplateTarget.innerHTML
        this.addGuarantorTarget.insertAdjacentHTML('beforeend', addButton)

        // The first guarantor in the list should not have a remove button
        const removeButtons = Array.from(
          document.getElementsByClassName('remove-guarantor')
        )
        removeButtons.forEach((removeButton) => {
          removeButton.remove()
        })
      }
    }

    if (
      document.getElementById('lease_application_guarantor_check_true').checked
    ) {
      if (this.count == 0) {
        this.showGuarantors()
      }
    }
  }

  showGuarantors() {
    const content = this.guarantorTemplateTarget.innerHTML
    this.addGuarantorTarget.insertAdjacentHTML('beforebegin', content)

    // Add the add button to the initial guarantor
    const addButton = this.buttonTemplateTarget.innerHTML
    this.addGuarantorTarget.insertAdjacentHTML('beforeend', addButton)

    // The first guarantor in the list should not have a remove button
    const removeButtons = Array.from(
      document.getElementsByClassName('remove-guarantor')
    )
    removeButtons.forEach((removeButton) => {
      removeButton.remove()
    })

    // If this is first time displaying guarantors
    // Increment the count so initial child index is 1
    if (this.count == 0) {
      this.count++
    }
  }

  hideGuarantors() {
    // Get all active guarantor forms, loop through them
    // Change their display to none and update form to not save them
    const guarantorForms = Array.from(
      document.getElementsByClassName('nested-guarantor-fields active')
    )
    if (guarantorForms.length == 0) return

    guarantorForms.forEach((guarantorForm) => {
      this.remove(guarantorForm)
    })

    this.addButtonTarget.remove()
  }

  addGuarantor() {
    // Get the current count of guarantors
    const guarantorCount = Array.from(
      document.getElementsByClassName('nested-guarantor-fields active')
    ).length

    // Limit to 4 guarantors
    // Add 1 to the count to account for the element that will be added
    if (guarantorCount + 1 <= 4) {
      // Add the child index to the template
      const content = this.templateTarget.innerHTML.replace(
        /TEMPLATE_RECORD/g,
        this.count
      )
      // Increment the count for the next child guarantor
      this.count++
      this.addGuarantorTarget.insertAdjacentHTML('beforebegin', content)
    }

    if (guarantorCount == 3) {
      this.addGuarantorTarget.style.display = 'none'
    }
  }

  remove(guarantorForm) {
    // Remove the active class from this guarantor target
    // Destroy value set to 1 so it is not saved
    // Hide the form
    guarantorForm.classList.remove('active')
    guarantorForm.querySelector("input[name*='_destroy']").value = 1
    guarantorForm.style.display = 'none'

    const inputsToHide = guarantorForm.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputsToHide.forEach((input) => {
      input.style.display = 'none'
    })
  }
}
