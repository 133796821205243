import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal', 'spinner'];

  openModal(event) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );

    modalController.open();
  }

  displaySpinner(event) {
    let spinnerController = this.application.getControllerForElementAndIdentifier(
      this.spinnerTarget,
      "spinner"
    );


    spinnerController.display();
  }

  openModalWithCheckBox(event) {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        "modal"
    );

    modalController.open();
  }
}
