import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    this.element.classList.add("show");
  }

  close() {
    this.element.classList.remove("show");
  }
}
